<template>
  <div class="flex flex-col w-full m-8 bg-white rounded-lg p-4">

    <div
      class="flex justify-center text-2xl text-il-dark font-bold"
    >
      {{ getUserCurrentCompanyX.name }}
    </div>

    <div
      class="text-center text-gray-400 uppercase text-sm mt-2"
    >
      company dashboard
    </div>

    <div class="flex justify-center">
      <div
        @click="navigateToMX('company-settings')"
        class=" py-2 px-4 mt-2 uppercase rounded-lg text-sm cursor-pointer border border-il-dark text-il-dark bg-white hover:bg-il-dark hover:text-white transition-all duration-200 ease-in-out"
      >
        <i class="fas fa-cog mr-2"></i> Open settings
      </div>
    </div>

    <div class="mt-8 flex">
      <div class="flex flex-col w-full lg:w-6/12 p-4">
        <div
          class="text-white bg-il-dark text-center font-bold text-xl p-2 uppercase w-full rounded-t-lg"
        >
          Clients List
        </div>
        <div class="flex flex-col p-4 shadow-md w-full rounded-b-lg mb-4">
          <div
            class="p-2 border-b border-gray-200"
            v-for="client in getUserCurrentCompanyX.clients"
            :key="client.id"
          >
            {{ client.name }}
          </div>

          <div class="mt-4 flex justify-center">
            <div
              class=" py-2 px-4 mt-2 uppercase rounded-lg text-sm cursor-pointer border border-il-dark text-il-dark bg-white hover:bg-il-dark hover:text-white transition-all duration-200 ease-in-out"
              @click="navigateToMX('clients')"
            >
              <i class="fas fa-users"></i>
              <span class="ml-2">Show Clients</span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';

  export default {
    components: {

    },
    mounted() {
    },
    methods: {
      ...mapMutations([
        'setUserCurrentCompanyNameX',
        'setUserCurrentCompanyX'
      ])
    },
    computed: {
      ...mapGetters([
        'getUserCurrentCompanyX',
        'getCompanySwitchKeyX'
      ]),
    },
  }
</script>